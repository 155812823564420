<template>
  <div class="w-full h-full relative" id="container-tunnel-map">
    <div
      class="absolute w-full h-full bg-black z-20 opacity-50"
      :class="{
        hidden: !cadastre.is_api_loading && map_is_loaded,
      }"
    ></div>
    <div
      v-if="cadastre.is_api_loading || !map_is_loaded"
      class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-30"
    >
      <LoaderPromy classes="w-6" :color="'#10AAAF'"></LoaderPromy>
    </div>
    <div class="absolute top-4 right-10 sm:right-4 z-10"><Tips /></div>
    <div class="absolute left-12 bottom-12 z-30 lg:z-10 lg:left-4">
      <MapType />
    </div>
    <div class="wrapper-map"><div id="mapbox"></div></div>

    <div
      v-if="showBtnParcelle"
      class="absolute top-0 left-0 sm:left-1/3 sm:-translate-x-1/3 pl-12 pt-10 flex flex-col sm:pl-2 sm:pt-6"
    >
      <div
        class="font-bold text-3xl mb-2 text-promy-blue-300 bg-white rounded p-3 bg-opacity-90 sm:text-base"
        v-if="form.informations.code_insee"
      >
        Est-ce bien votre parcelle ?
      </div>
    </div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/map/mapbox'
import batiment from '@/mixins/batiment'
import parcelle from '@/mixins/parcelle'
import { mapGetters } from 'vuex'
import AggregateParcelle from './Actions/AggregateParcelle'

export default {
  data() {
    return {
      map_is_loaded: false,
    }
  },
  components: {
    AggregateParcelle,
  },
  mixins: [Mapbox, batiment, parcelle],
  props: {
    showBtnParcelle: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
    cadastre: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapGetters({
      map: 'map/map',
      active_layer: 'map/active_layer',
      currentStep: 'Tunnel/GET_STEP_NUMBER',
    }),
    parcelles_selected_in_revelation() {
      return this.form.informations.current_parcelle &&
        Object.keys(this.form.informations.current_parcelle).length !== 0
        ? [
            this.form.informations.current_parcelle,
            ...this.form.informations.parcelles_voisines,
          ]
        : []
    },
  },
  mounted() {
    this.map_is_loaded = false
    if (!this.map) {
      if (!this.cadastre.is_api_loading && this.currentStep === 1) {
        this.loadMap()
          ? this.loadDataToMap()
          : this.map.on('load', this.loadDataToMap)
        this.map_is_loaded = true
      }
    } else {
      this.map_is_loaded = true
      document.querySelector('#mapbox').replaceWith(this.map.getContainer())
      this.$emit('mapLoaded')
      if (this.$route.name === 'revelationStep1') {
        this.map.on('click', this.selectOrUnselectParcelles)
      }
    }
  },
  methods: {
    setBatimentsAndSuperficie() {
      if (!this.cadastre.is_loaded_cross_revelation) {
        let own_parcelles = [
          this.form.informations.current_parcelle,
          ...this.form.informations.parcelles_voisines,
        ].filter((parcelle) => parcelle)
        this.form.informations.batiments = this.getAllBatimentsWithUniqueId(
          this.cadastre.data,
          own_parcelles,
        )

        this.form.informations.superficie_totale = Math.round(
          this.$turf.area(this.$turf.featureCollection(own_parcelles)),
        )
        this.form.informations.superficie_construite = Math.round(
          this.$turf.area(
            this.$turf.featureCollection(this.form.informations.batiments),
          ),
        )
      }
    },
    loadDataToMap() {
      if (!this.cadastre.is_api_loading) {
        this.form.informations.current_parcelle =
          this.form.informations.current_parcelle

        let own_parcelles = [
          this.form.informations.current_parcelle,
          ...this.form.informations.parcelles_voisines,
        ]

        this.setBatimentsAndSuperficie()
        let fc_own_parcelles = this.$turf.featureCollection(own_parcelles)
        this.fitBoundsGeojsonInMap(fc_own_parcelles)
        this.addLayerGeoParcelles()
        this.setPaintParcellesSelected()

        this.cadastre.is_loaded_cross_gu = false
        this.cadastre.is_loaded_cross_revelation = false
        this.map.on('click', this.selectOrUnselectParcelles)
      }
      this.$store.commit('map/MAP_STYLLE_LOADED', true)
      this.$emit('mapLoaded')
      this.map_is_loaded = true
    },
    selectOrUnselectParcelles(e) {
      let features = this.map.queryRenderedFeatures(e.point, {
        layers: [this.source_and_layers_geo_parcelles[1]],
      })
      if (features.length) {
        let id_parcelle_selected = features[0].properties.id
        if (
          !_.includes(
            this.parcelles_selected_in_revelation.map(
              (item) => item.properties.id,
            ),
            id_parcelle_selected,
          )
        ) {
          this.$http
            .get('/grand-publics/get-parcelles/' + id_parcelle_selected, {})
            .then((response) => {
              this.cadastre.data = [
                ...this.cadastre.data,
                ...response.data.cadastre_principal,
              ]
              this.setParcelles()
              this.setPaintParcellesSelected()
            })
        } else {
          this.cadastre.data = this.cadastre.data.filter(
            (item) => item.parcelle.properties.id !== id_parcelle_selected,
          )
          this.setParcelles()
          this.setPaintParcellesSelected()
        }
      }
    },
    setParcelles() {
      let parcelles = this.cadastre.data.map((item) => item.parcelle)
      this.form.informations.current_parcelle =
        this.getParcellePrincipalThroughCenterOfAllParcelles(parcelles)
      this.form.informations.parcelles_voisines = parcelles.filter(
        (parcelle) =>
          parcelle.properties.id !==
          this.form.informations.current_parcelle.properties.id,
      )
      if (this.form.informations.current_parcelle) {
        this.$emit(
          'updateAddress',
          this.$turf
            .pointOnFeature(this.form.informations.current_parcelle)
            .geometry.coordinates.map((coord) => coord.toString()),
        )
      }
    },
    setPaintParcellesSelected() {
      if (!this.parcelles_selected_in_revelation.length) {
        this.map.setPaintProperty(
          this.source_and_layers_geo_parcelles[1],
          'fill-color',
          '#8F6DB0',
        )
      } else {
        this.map.setPaintProperty(
          this.source_and_layers_geo_parcelles[1],
          'fill-color',
          [
            'match',
            ['get', 'id'],
            this.parcelles_selected_in_revelation.map(
              (parcelle) => parcelle.properties.id,
            ),
            '#00b7ab',
            '#8F6DB0',
          ],
        )
      }
    },
  },
  watch: {
    'cadastre.is_api_loading': {
      deep: true,
      handler(is_loading) {
        if (!is_loading && this.currentStep === 1) {
          let is_map_loaded = this.loadMap()
          is_map_loaded
            ? this.loadDataToMap()
            : this.map.on('load', this.loadDataToMap)
        }
      },
    },
  },

  beforeDestroy() {
    this.map.off('click', this.selectOrUnselectParcelles)
    this.$store.commit('map/MAP_STYLLE_LOADED', false)
  },
}
</script>
<style lang="scss" scoped>
.wrapper-map {
  @apply w-full;
  @screen lg {
    height: 70vh;
  }
  height: calc(100vh - 104px);
}

.center-element {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
</style>
