import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      infos_parcelles: 'address/infos_parcelles',
      parcelles_selected: 'parcelle/parcelles_selected',
    }),
  },
  methods: {
    getSurfaceMesurer(geojson) {
      return this.roundDouble(this.$turf.area(geojson))
    },
    roundDouble(number) {
      return Math.round((number * 10) / 10)
    },
    getIntersectBatimentsWithMultiParcelle(
      parcelles,
      batiments,
      current_parcelle = null,
    ) {
      let intersect_batiments = []

      batiments.forEach((batiment) => {
        let intersect_martinez = this.$martinez.intersection(
          parcelles.geometry.coordinates,
          batiment.geometry.coordinates,
        )
        if (intersect_martinez) {
          intersect_martinez =
            this.getCoordinates(batiment.geometry.coordinates).length > 1
              ? [intersect_martinez]
              : intersect_martinez
          intersect_martinez.forEach((poly) => {
            try {
              let poly_bati = this.$turf.polygon(this.getCoordinates(poly))
              if (this.getSurfaceMesurer(poly_bati) > 0) {
                if (current_parcelle) {
                  this.linkedBatimentWithTheirPacelle(
                    poly_bati,
                    current_parcelle,
                    intersect_batiments,
                  )
                } else intersect_batiments.push(poly_bati)
              }
            } catch (e) {
              console.log('Invalid GeoJSON', e)
            }
          })
        }
      })
      return intersect_batiments
    },
    linkedBatimentWithTheirPacelle(
      poly_bati,
      current_parcelle,
      intersect_batiments,
    ) {
      poly_bati.properties.id_parcelles = []
      poly_bati.properties.refs_parcelle = []
      poly_bati.properties.id_parcelles.push(current_parcelle.properties.id)
      poly_bati.properties.refs_parcelle.push(
        this.getRefParcelle(current_parcelle),
      )
      intersect_batiments.push(poly_bati)
    },
    getAllBatimentsWithUniqueId(
      features = this.infos_parcelles,
      parcelles_selected = this.parcelles_selected,
    ) {
      if (!parcelles_selected.length) return []
      let batiments = []
      let union_parcelles = this.$turf.union(...parcelles_selected)
      features.forEach((feature) => {
        let batis = this.getIntersectBatimentsWithMultiParcelle(
          union_parcelles,
          feature.batiments.features,
          feature.parcelle,
        )
        batiments.push(...batis)
      })
      batiments = this.removeDuplicateBatiments(batiments)

      return batiments
    },
    removeDuplicateBatiments(batiments) {
      let remove_index_batis = []
      batiments.forEach((batiment, index) => {
        batiments.forEach((temp_batiment, child_index) => {
          if (
            _.isEqual(
              batiment.geometry.coordinates,
              temp_batiment.geometry.coordinates,
            ) &&
            index !== child_index &&
            remove_index_batis.indexOf(index) === -1
          ) {
            if (this.isExist(batiments[index].properties.id_parcelles)) {
              batiments[index].properties.id_parcelles.push(
                ...temp_batiment.properties.id_parcelles,
              )
              batiments[index].properties.refs_parcelle.push(
                ...temp_batiment.properties.refs_parcelle,
              )
            }

            remove_index_batis.push(child_index)
          }
        })
      })
      batiments = batiments.filter((bati, index) => {
        return remove_index_batis.indexOf(index) === -1
      })
      return batiments
    },
    getRefParcelle(parcelle) {
      return parcelle.properties.section + '' + parcelle.properties.numero
    },
    setLayerSelectedBatiment(batiment, reset = false) {
      if (!reset) {
        this.map.setPaintProperty(
          this.source_layers_batiments[1],
          'fill-extrusion-color',
          [
            'match',
            ['get', 'id'],
            [batiment.properties.id],
            '#00C9BC',
            '#356390',
          ],
        )
      } else {
        this.map.setPaintProperty(
          this.source_layers_batiments[1],
          'fill-extrusion-color',
          '#356390',
        )
        this.map.setPaintProperty(
          this.source_layers_all_parcelle[2],
          'line-opacity',
          1, // 1
        )
      }
    },
    isSameBati(old_bati, new_bati) {
      if (old_bati) {
        return old_bati.properties.id === new_bati.properties.id
      } else false
    },
    getMultiPolygon(coordinates) {
      return this.$turf.multiPolygon([this.getCoordinates(coordinates)])
    },
    surfaceSolBati(batiment) {
      var multiPolygon = this.getMultiPolygon(batiment.geometry.coordinates)
      return this.$turf.area(multiPolygon)
    },
    totalSurfaceSolBatis(allBatiments) {
      let surface = 0
      allBatiments.forEach((batiment) => {
        surface += this.surfaceSolBati(batiment)
      })
      return surface
    },
    nombreMursbati(batiment) {
      let nombre_murs = 0
      let multi_coords = this.getCoordinates(batiment.geometry.coordinates)
      multi_coords.forEach((coord_polygone) => {
        nombre_murs += coord_polygone.length - 1
      })
      return nombre_murs
    },
    totalNombreMursBatis(batis) {
      let nombre_murs = 0
      batis.forEach((batiment) => {
        nombre_murs += this.nombreMursbati(batiment)
      })
      return nombre_murs
    },
    perimetreBati(batiment) {
      return Math.round(
        this.$turf.length(batiment, {
          unit: 'kilometers',
        }) * 1000,
      )
    },
    totalPerimetreBatis(batis) {
      let perimetre = 0
      batis.forEach((batiment) => {
        perimetre += this.perimetreBati(batiment)
      })
      return perimetre
    },
  },
}
